<template src="./contact.htm">
 


</template>

<script>
import Form from '@/utils/form'
import { mapState } from 'vuex';
export default {
  data() {
        return {
            show1: false,
            form: new Form({
                name: '',
                email: '',
                message:'',
            })
        }
    },
  
};
</script>